const CONFIG = {
  EMAIL: 'forsikring@volkswagen.no',
  ADVANCE_PURCHASE_INFO_LINK: 'https://forsikring.volkswagen.no/om',
  SUPPORT_LINK: '',
  MAIN_LINK: {
    vehicle: 'https://forsikring.volkswagen.no/om',
  },
  INSURANCE: 'https://volkswagen.no/forsikring',
  PRIVACY_POLICY_LINK: 'https://www.digisure.no/personopplysninger',
  PARTNER_PRIVACY_POLICY_LINK: 'https://mollerbil.no/personvern/',
  PARTNER_NAME: 'volkswagen',
  PARTNER_NAME_AVTALEGIRO: 'Digisure for Volkswagen Forsikring',
  VIPPS_AUTH: true,
  TRANSLATION_LANGUAGES: ['no-NO'],
  GOOGLE_RECAPTCHA_LINKS: {
    PRIVACY: 'https://policies.google.com/privacy?hl=no',
    TERMS: 'https://policies.google.com/terms?hl=no',
  },
  DOCUMENTS: {
    PRIVACY_STATEMENT: 'https://drive.google.com/file/d/1GKqBnGF_XcXQOa1STNtKJrnEiSsXcVKP/view',
    LP: {
      IPID: './assets/docs/MMG/IPID__MMG_VW.pdf',
      ANSVAR: './assets/docs/MMG/Vilkar_Ansvar_Volkswagen.pdf',
      DELKASKO: './assets/docs/MMG/Vilkar_delkasko_Volkswagen.pdf',
      KASKO: './assets/docs/MMG/Vilkar_Kasko_Volkswagen.pdf',
      SUPER: './assets/docs/MMG/Vilkar_super_Volkswagen.pdf',
    },
  },
}

export default CONFIG
