import authMobileBg from './images/auth-mobile-bg.jpg'
import contentCardRightSide from './images/content-card-right-side.jpg'
import doneDesktopBg from './images/done-desktop-bg.jpg'
import doneMobileBg from './images/done-mobile-bg.jpg'
import loadingDesktopBg from './images/loading-desktop-bg.png'
import loadingMobileBg from './images/loading-mobile-bg.png'
import landingMainBg from './images/landing-main-bg.jpg'

import { ProductName } from '../../types/interface/quote.interface'
import {
  ThemeBrandAssetsType,
  ThemeBrandColorsType,
  ThemeBrandTypographyType,
} from 'BrandTheme/types'
import brandTheme from '../theme/utils'
import logoDark from './images/logoDark.png'
import authCardBg from './images/auth-card-bg.jpg'

const initCallback = () => {
  const productName: ProductName = ProductName.VEHICLE
  const collisionMobileBackground = authMobileBg

  const brandColors: ThemeBrandColorsType = {
    main: '#001E50',
    secondary: '#001E50',
    secondary2: '#00437A',
    background: '#FAFAFA',
    headingMain: '#001E50',
    headingSecondary: '#333F48',
    textMain: '#333F48',
    textSecondary: '#768692',
    textError: '#F84971',
    textPlaceholder: '#C8CFD3',
    textDisclaimer: '#B6BABC',
    textInactive: '#C8CFD3',
    buttonActive: '#001E50',
    buttonInactive: '#d5d5d5',
    inputActive: '#001E50',
    inputInactive: '#768692',
    linkMain: '#000',
    linkSecondary: '#001E50',
    modal: '#C4C4C4',
    shadow: '#000000',
    clean: '#FFFFFF',
    cardHeader: '#001E50',
    textCardHeader: '#FFF',
    textCardFooter: '#001E50',
  }

  const productBackgroundsMap = {
    [ProductName.VEHICLE]: {
      loadingMobile: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 20%), 
      url(${loadingMobileBg}) no-repeat center bottom`,
      loading: `url(${loadingDesktopBg}) no-repeat center center fixed`,
      authDesktop: `radial-gradient(100% 1045.07% at 100% 100%, ${brandColors.main} 0%, ${brandColors.secondary2} 100%)`,
      authMobileBg: `url(${authMobileBg}) no-repeat left top scroll`,
      reviewBg: '',
    },
  }

  const loadingBackground = productBackgroundsMap[productName].loading
  const loadingMobileBackground = productBackgroundsMap[productName].loadingMobile

  const mainBackgroung = productBackgroundsMap[productName].authDesktop
  const mainBackgroungMobile = productBackgroundsMap[productName].authMobileBg

  const reviewBackground = productBackgroundsMap[productName].reviewBg

  const brandTypography: ThemeBrandTypographyType = {
    heading: {
      h1: {
        fontSize: '1.75rem', // 28px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h2: {
        fontSize: '1.5rem', // 24px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h3: {
        fontSize: '1.25rem', // 20px
        fontWeight: 400,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h4: {
        fontSize: '1rem', // 16px
        fontWeight: 500,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
      h6: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
        color: brandColors.headingMain,
        lineHeight: 1.4,
      },
    },
    text: {
      xSmall: {
        fontSize: '0.688rem', // 11px
        lineHeight: 1.4,
      },
      small: {
        fontSize: '0.75rem', // 12px
        lineHeight: 1.4,
      },
      normal: {
        fontSize: '1rem', // 16px
        lineHeight: 1.4,
      },
      large: {
        fontSize: '1.5rem', // 24px
        lineHeight: 1.4,
      },
      xLarge: {
        fontSize: '2.5rem', // 40px
        lineHeight: 1.4,
      },
    },
    modifiers: {
      bold: {
        fontWeight: 700,
      },
      decorated: {
        textDecoration: 'underline',
      },
      unDecorated: {
        textDecoration: 'none',
        '& *': {
          textDecoration: 'none',
        },
      },
      withHover: {
        '&:hover': {
          opacity: 0.75,
          cursor: 'pointer',
        },
      },
      preFormatted: {
        whiteSpace: 'pre',
      },
    },
    overrides: {
      contactCustomerSwitchText: {
        color: brandColors.textSecondary,
      },
      reviewRightColItem: {
        color: brandColors.main,
      },
      authTitle: {
        fontSize: '26px !important',
      },
      landingTitle: {
        color: `${brandColors.clean} !important`,
        padding: `0 24px 100px 24px`,
        [brandTheme.theme.breakpoints.down('md')]: {
          padding: brandTheme.theme.spacing(3),
        },
      },
    },
    extraFonts: [],
  }

  const theme = brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
  }).theme

  const overrides = {
    contained: {
      '&:hover': {
        color: brandColors.clean,
        opacity: 0.75,
        '& .MuiSvgIcon-root': {
          color: brandColors.clean,
        },
      },
      '&:disabled': {
        backgroundColor: brandColors.buttonInactive,
        color: brandColors.textInactive,
      },
    },
    MuiCardActionArea: {
      root: {
        '& .MuiTouchRipple-root': {
          color: brandColors.main,
        },
      },
      focusHighlight: {
        backgroundColor: brandColors.main,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: brandColors.textMain,
        '&.Mui-selected': {
          color: brandColors.secondary,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: brandColors.textMain,
      },
    },
    MuiCheckbox: {
      root: {
        color: `${brandColors.main} !important`,
      },
    },
    MuiSwitch: {
      root: {
        width: 74,
        height: 48,
        overflow: 'visible',
        '& .MuiSwitch-track': {
          width: 70,
          borderRadius: 12,
        },
        '& .MuiSwitch-input': {
          width: '200%',
        },
        '& .MuiSwitch-thumb': {
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-switchBase': {
          top: 6,
          left: 6,
          '& .MuiIconButton-label': {
            color: brandColors.clean,
          },
          '&.Mui-checked': {
            transform: 'translateX(30px)',
            left: 2,
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: brandColors.clean,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: brandColors.linkSecondary,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiButton: {
      root: {
        height: 48,
        borderRadius: '28px',
      },
      outlined: {
        '&:hover': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
        },
        '&:hover svg': {
          fill: brandColors.clean,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          color: brandColors.clean,
        },
        '&:active svg': {
          fill: brandColors.clean,
        },
        '@media (hover:none)': {
          '&:hover': {
            color: brandColors.main,
            backgroundColor: brandColors.clean,
          },
          '&:hover svg': {
            fill: brandColors.main,
          },
          '&:active': {
            backgroundColor: brandColors.main,
            color: brandColors.clean,
          },
          '&:active svg': {
            fill: brandColors.clean,
          },
        },
      },
      contained: {
        '&:hover': {
          color: brandColors.clean,
          backgroundColor: `${brandColors.buttonActive} !important`,
          opacity: 0.7,
          '& .MuiSvgIcon-root': {
            color: brandColors.clean,
          },
        },
        '&:active': {
          backgroundColor: `${brandColors.buttonActive} !important`,
          opacity: 0.7,
        },
        '@media (hover:none)': {
          '&:hover': {
            opacity: '1 !important',
          },
        },
        '&:disabled': {
          backgroundColor: brandColors.buttonInactive,
          color: brandColors.clean,
        },
      },
    },
  }

  const brandAssets: ThemeBrandAssetsType = {
    pageBackgrounds: {
      main: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      mainMobile: {
        background: mainBackgroungMobile,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      },
      mainCard: {
        background: `url(${authCardBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
      },
      secondary: {
        background: `radial-gradient(100% 1045.07% at 100% 100%, ${brandColors.main} 0%, ${brandColors.secondary2} 100%)`,
        backgroundSize: 'cover',
      },
      secondaryMobile: {
        background: `url(${doneMobileBg}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      secondaryCard: {
        background: `url(${doneDesktopBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      error: {
        background: mainBackgroung,
        backgroundSize: 'cover',
      },
      errorCardMain: {
        background: `url(${contentCardRightSide}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      errorCardMobileMain: {
        background: `url(${collisionMobileBackground}) no-repeat left top scroll`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      loading: {
        alignContent: 'center',
        background: loadingBackground,
        backgroundSize: 'cover',
      },
      loadingMobile: {
        background: loadingMobileBackground,
        backgroundSize: 'cover',
      },
      review: {
        background: reviewBackground,
        backgroundSize: 'contain',
      },
    },
    images: {
      changeVehicleOwnerPageCard: {
        background: `url(${contentCardRightSide}) no-repeat right top scroll`,
        backgroundSize: '50%, auto, cover',
        backgroundPosition: 'right',
      },
      logoDesktopHeader: {
        background: `url(${logoDark}) no-repeat left center scroll`,
        backgroundSize: 352,
        minWidth: 352,
        display: 'flex',
        flex: 1,
      },
      logoMobileHeader: {
        background: `url(${logoDark}) no-repeat left center scroll`,
        backgroundSize: 'contain',
        height: 60,
        minWidth: 245,
      },
      logoMax: {
        width: 309,
        margin: '40px 10px',
        alignSelf: 'start',
      },
      landingMainBg: {
        background: `url(${landingMainBg}) no-repeat right bottom scroll`,
      },
    },
    layout: {
      card: {
        cursor: 'pointer',
        '&:hover p, &:visited p, &:active p': {
          color: brandColors.clean,
        },
        '&:hover, &:visited': {
          backgroundColor: brandColors.main,
        },
        '&:active': {
          backgroundColor: brandColors.main,
          opacity: 0.7,
        },
      },
      mobileButton: {
        borderRadius: 28,
        '& p': {
          color: brandColors.clean,
        },
        '&:hover': {
          opacity: '.75',
          background: brandColors.main,
        },
      },
      mobileCustomTitle: {
        fontSize: '20px',
        marginBottom: 0,
      },
      mobileCustomHeadingText: {
        marginTop: 'auto',
        color: brandColors.clean,
      },
      mobileCustomSubTitle: {
        color: brandColors.clean,
        fontSize: 12,
        paddingTop: 8,
      },
      LMBgHeaderHeader: {
        [theme.breakpoints.down('sm')]: {
          backgroundPositionY: '-20px',
          height: 350,
        },
      },
      loadingTitle: {
        color: `${brandColors.clean} !important`,
      },
      loadingTitleMobile: {
        marginTop: '14px',
        color: `${brandColors.headingMain}  !important`,
      },
      customLabelRadio: {
        overflow: 'hidden',
        '&:nth-child(1)': {
          borderRadius: '50px 0px 0px 50px',
        },
        '&:nth-child(2)': {
          borderRadius: '0px 50px 50px 0px',
        },
      },
      customInputRadio: {
        backgroundColor: 'none',
        '&:hover ': {
          color: brandColors.clean,
        },
      },
      btnNext: {
        border: `1px solid ${brandColors.buttonActive}`,
        color: brandColors.main,
      },
      iconCustomColor: {
        color: brandColors.secondary,
      },
      pageBackgroundSm: {
        backgroundPositionY: '-20px',
      },
      customHeaderTextWrapper: {
        position: 'absolute',
        background: `radial-gradient(100% 1045.07% at 100% 100%, ${brandColors.main} 0%, ${brandColors.secondary2} 100%)`,
        left: 0,
        right: 0,
        top: 220,
        zIndex: 10,
        padding: '16px 0px 16px 16px',
      },
      authDisclaimer: {
        marginTop: 20,
      },
      buttonLink: {
        background: 'none',
        maxWidth: 'fit-content',
        boxShadow: 'none',
        color: brandColors.shadow,
        textTransform: 'capitalize',
        fontSize: 12,
        padding: 0,
        height: 'auto',
        textDecoration: 'underline',
        marginBottom: 4,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: brandColors.shadow,
          boxShadow: 'none',
          textDecoration: 'underline',
        },
      },
      customMobileSubmit: {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          bottom: 0,
        },
      },
    },
  }
  return brandTheme.setup({
    productName,
    colors: brandColors,
    typography: brandTypography,
    assets: brandAssets,
    overrides,
  })
}

export default brandTheme.init(initCallback)
